import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  LoadingSpinner,
  RadioGroup,
  RadioGroupItem,
  toast,
} from '@edx/react-common'
import { FaCloudUploadAlt, FaTrash } from 'react-icons/fa'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { DogDetail, edxApi } from '../services/edx-api.ts'
import { EdxApiTypes } from '@edx/api'
import { DogButton } from './DogButton.tsx'
import { makeEdxImageUrl } from '../utils/make-edx-image-url.ts'
import { AvatarCropperModal } from './AvatarCropperModal.tsx'
import { base64ToFile } from '../utils/base64-to-file.ts'
import { dogBreeds } from '../utils/dogBreeds.ts'
import { dogDisciplines } from '../utils/dogDisciplines.ts'
import { SelectSearchable } from './SelectSearchable.tsx'

export type IDogFormValues = {
  name?: string
  breed?: string
  discipline?: string
  gender?: EdxApiTypes.DogGender
  /** @format date-time */
  dateOfBirth?: string
}

const formSchema = z.object({
  avatar: z.string().optional(),
  dateOfBirth: z.string().min(1, 'Required'),
  breed: z.string(),
  discipline: z.string().optional(),
  gender: z
    .string({
      message: 'Required',
    })
    .min(1, 'Required'),
  name: z.string().min(1, 'Required'),
})

interface DogFormProps {
  dogId?: number
  onCancel: () => void
  onComplete: (dog: DogDetail) => void
}

export function DogForm({ dogId, onCancel, onComplete }: DogFormProps) {
  const [loading, setLoading] = useState(!!dogId)
  const [avatar, setAvatar] = useState<{ previewBase64?: string; url?: string }>({})

  const [showAvatarCropper, setShowAvatarCropper] = useState(false)

  const updateAvatar = (imgSrc: string) => {
    setAvatar({ previewBase64: imgSrc })
  }

  const form = useForm<IDogFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  })

  const [dateOfBirth] = form.watch(['dateOfBirth'])

  useEffect(() => {
    if (!dogId) return

    setLoading(true)

    edxApi.api
      .dogsDetail(dogId)
      .then(({ data }) => {
        form.reset({
          dateOfBirth: data.dateOfBirth ?? '',
          gender: data.gender,
          name: data.name ?? '',
          breed: data.breed ?? '',
          discipline: data.discipline ?? '',
        })

        if (data.avatar?.url) {
          setAvatar({ previewBase64: makeEdxImageUrl(data.avatar.url), url: makeEdxImageUrl(data.avatar.url) })
        } else {
          setAvatar({})
        }

        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        toast({
          title: 'Failed to Load Dog',
          status: 'error',
          description: 'An error occurred while loading the dog',
        })
        onCancel()
      })
  }, [dogId])

  const handleSaveDog = async (values: IDogFormValues) => {
    setLoading(true)

    const promise = dogId ? edxApi.api.dogsUpdate(dogId, values) : edxApi.api.dogsCreate(values)

    await promise
      .then(async ({ data }) => {
        const _dogId = dogId ?? data!

        if (avatar.previewBase64 && avatar.previewBase64 != avatar.url) {
          const file: File = base64ToFile(avatar.previewBase64, 'avatar')
          const metadata = {
            ContentType: file.type,
            ContentDisposition: `inline; filename="${file.name}"`,
            Headers: {
              // 'Custom-Header': ['CustomValue'],
            },
            Length: file.size,
            Name: file.name,
            FileName: file.name,
          }

          const formData = new FormData()
          formData.append('file', file)
          formData.append('metadata', JSON.stringify(metadata))

          await edxApi.api
            .dogsAvatarCreate(
              _dogId,
              formData as {
                ContentType?: string
                ContentDisposition?: string
                Headers?: Record<string, string[]>
                Length?: number
                Name?: string
                FileName?: string
              },
            )
            .catch((error) => {
              console.error(error)
              toast({
                title: 'Failed to Upload Dog Avatar',
                status: 'error',
                description: 'An error occurred while uploading the dog avatar',
              })
            })
        } else if (!avatar.previewBase64 && avatar.url) {
          await edxApi.api.dogsAvatarDelete(_dogId).catch((error) => {
            console.error(error)
            toast({
              title: 'Failed to Delete Dog Avatar',
              status: 'error',
              description: 'An error occurred while deleting the dog avatar',
            })
          })
        }

        return { data }
      })
      .then(({ data }) => {
        const id = dogId ?? data!

        return edxApi.api.dogsDetail(id)
      })
      .then(({ data }) => {
        toast({
          title: dogId ? 'Dog Updated' : 'Dog Created',
          status: 'success',
          description: `Your dog has been ${dogId ? 'updated' : 'saved'}`,
        })

        onComplete(data)
      })
      .catch((error) => {
        console.error(error)

        setLoading(false)

        toast({
          title: 'Failed to Save Dog',
          status: 'error',
          description: 'An error occurred while saving the dog',
        })
      })
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-6" onSubmit={form.handleSubmit(handleSaveDog)}>
        <div className="flex justify-center">
          <div className="bg-gray-200 w-[216px] h-36 rounded relative">
            {(avatar.url || avatar.previewBase64) && (
              <div
                className="absolute top-1 right-1 bg-white rounded-full p-2 cursor-pointer"
                onClick={() => {
                  setAvatar({ ...avatar, previewBase64: '' })
                }}
              >
                <FaTrash className="text-red-700" size={14} />
              </div>
            )}

            <img
              className="w-full h-full rounded"
              src={
                !(avatar.previewBase64 || avatar.url)
                  ? 'https://placehold.co/216x144?text=Dog+Photo'
                  : (avatar?.previewBase64 ?? avatar?.url)
              }
              alt=""
            />
            <div
              className="absolute bottom-1 right-1 bg-white rounded-full px-2 py-1 cursor-pointer text-sm"
              onClick={() => {
                setShowAvatarCropper(true)
              }}
            >
              <span className="flex items-center gap-2">
                <FaCloudUploadAlt size={14} /> Upload avatar
              </span>
            </div>
          </div>
        </div>

        <div>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex flex-col md:flex-row gap-4">
          <FormField
            control={form.control}
            name="breed"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Breed</FormLabel>
                <FormControl>
                  <SelectSearchable
                    options={dogBreeds}
                    onValueChange={field.onChange}
                    placeholder="Select breed"
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="discipline"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Discipline</FormLabel>
                <FormControl>
                  <SelectSearchable
                    options={dogDisciplines}
                    onValueChange={field.onChange}
                    placeholder="Select discipline"
                    value={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex gap-4">
          <FormField
            control={form.control}
            name="dateOfBirth"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Birthdate</FormLabel>
                <FormControl>
                  <DatePicker
                    disabled={{ after: new Date() }}
                    onChangeValue={(value) => form.setValue('dateOfBirth', value ? value.toISOString() : undefined)}
                    value={dateOfBirth ? new Date(dateOfBirth) : undefined}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="gender"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Sex</FormLabel>
                <FormControl>
                  <RadioGroup onValueChange={field.onChange} defaultValue={field.value} className="flex">
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem className="bg-white" value="Male" />
                      </FormControl>
                      <FormLabel className="font-normal">Male</FormLabel>
                    </FormItem>

                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem className="bg-white" value="Female" />
                      </FormControl>
                      <FormLabel className="font-normal">Female</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {showAvatarCropper && (
          <AvatarCropperModal updateAvatar={updateAvatar} onClose={() => setShowAvatarCropper(false)} />
        )}

        <DogButton classNames="text-edxred-500 font-montserrat" type="submit">
          Save
        </DogButton>
      </form>
    </Form>
  )
}
